import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
		<path d="M0 2500 l0 -2500 2500 0 2500 0 0 2500 0 2500 -2500 0 -2500 0 0
-2500z m2775 1695 c400 -86 725 -335 909 -695 196 -382 196 -840 0 -1220 -70
-135 -135 -223 -249 -336 -343 -339 -840 -464 -1310 -329 -104 30 -284 114
-370 174 -283 194 -478 483 -556 821 -26 110 -35 337 -20 452 42 316 206 624
439 824 147 127 287 208 462 268 147 50 240 64 423 65 132 1 176 -3 272 -24z
m-1741 -2781 c9 -8 16 -31 16 -50 0 -32 -2 -34 -35 -34 -31 0 -35 3 -35 26 0
21 -4 25 -22 22 -22 -3 -23 -6 -23 -118 0 -112 1 -115 23 -118 19 -3 22 1 22
27 0 29 3 31 35 31 33 0 35 -2 35 -33 0 -18 -7 -43 -16 -55 -13 -19 -24 -22
-76 -22 -95 0 -98 6 -98 167 0 169 2 173 92 173 47 0 71 -5 82 -16z m242 2
c17 -13 19 -29 22 -149 3 -126 2 -137 -17 -156 -17 -17 -34 -21 -81 -21 -94 0
-100 10 -100 172 0 161 4 168 95 168 37 0 69 -6 81 -14z m256 -3 c16 -14 18
-33 18 -165 l0 -148 -40 0 -40 0 0 140 c0 133 -1 140 -20 140 -19 0 -20 -7
-20 -140 l0 -140 -35 0 -35 0 0 155 c0 178 -2 174 94 175 42 0 65 -5 78 -17z
m242 1 c13 -13 16 -42 16 -165 l0 -149 -35 0 -35 0 0 140 c0 133 -1 140 -20
140 -23 0 -22 7 -20 -167 l1 -113 -40 0 -41 0 0 147 c0 179 2 183 96 183 44 0
67 -5 78 -16z m450 0 c9 -8 16 -31 16 -50 0 -32 -2 -34 -35 -34 -31 0 -35 3
-35 26 0 21 -4 25 -22 22 -22 -3 -23 -6 -23 -118 0 -112 1 -115 23 -118 19 -3
22 1 22 27 0 29 3 31 35 31 34 0 35 -1 35 -37 0 -56 -22 -73 -93 -73 -94 0
-97 6 -97 168 0 166 3 172 96 172 44 0 67 -5 78 -16z m586 -3 c5 -11 10 -79
10 -152 0 -163 -3 -169 -96 -169 -92 0 -94 3 -94 170 0 169 1 170 100 170 58
0 71 -3 80 -19z m244 3 c13 -13 16 -42 16 -165 l0 -149 -35 0 -35 0 0 140 c0
133 -1 140 -20 140 -23 0 -22 7 -20 -167 l1 -113 -40 0 -41 0 0 147 c0 179 2
183 96 183 44 0 67 -5 78 -16z m457 -1 c10 -12 14 -52 14 -155 0 -165 -2 -168
-99 -168 -93 0 -96 5 -96 171 0 167 1 169 99 169 52 0 72 -4 82 -17z m-1511
-23 c0 -28 -2 -30 -40 -30 -39 0 -40 -1 -40 -35 0 -34 1 -35 40 -35 38 0 40
-2 40 -30 0 -28 -2 -30 -40 -30 -39 0 -40 -1 -40 -35 0 -34 1 -35 40 -35 38 0
40 -2 40 -30 l0 -30 -75 0 -75 0 0 160 0 160 75 0 75 0 0 -30z m450 0 c0 -25
-4 -30 -25 -30 l-25 0 0 -130 0 -130 -37 0 -38 0 0 127 0 128 -27 3 c-24 3
-28 8 -28 33 l0 29 90 0 90 0 0 -30z m115 -130 l0 -155 -37 -3 -38 -3 0 161 0
161 38 -3 37 -3 0 -155z m725 131 c0 -24 -5 -30 -26 -33 l-26 -3 1 -127 0
-128 -39 0 -40 0 0 130 0 130 -25 0 c-21 0 -25 5 -25 30 l0 30 90 0 90 0 0
-29z m354 2 c3 -16 11 -63 18 -106 14 -89 19 -83 38 46 l13 87 39 0 c34 0 38
-3 34 -18 -6 -16 -66 -292 -66 -299 0 -2 -19 -3 -43 -3 l-43 0 -14 68 c-7 37
-18 90 -25 117 -7 28 -16 69 -20 93 l-7 42 35 0 c31 0 36 -4 41 -27z m256
-133 l0 -160 -35 0 -35 0 0 160 0 160 35 0 35 0 0 -160z m244 138 c10 -13 16
-43 16 -73 0 -71 -19 -95 -76 -95 l-44 0 0 -65 0 -65 -40 0 -40 0 0 160 0 160
84 0 c76 0 86 -2 100 -22z m-2724 -448 c0 -64 -3 -80 -15 -80 -12 0 -15 16
-15 80 0 64 3 80 15 80 12 0 15 -16 15 -80z m995 69 c10 -15 -1 -23 -20 -15
-9 3 -13 10 -10 16 8 13 22 13 30 -1z m355 -4 c0 -8 -7 -15 -15 -15 -8 0 -15
7 -15 15 0 8 7 15 15 15 8 0 15 -7 15 -15z m750 0 c0 -8 -7 -15 -15 -15 -8 0
-15 7 -15 15 0 8 7 15 15 15 8 0 15 -7 15 -15z m-2262 -17 c-3 -7 -14 -14 -26
-16 -20 -3 -22 -10 -22 -58 0 -41 -4 -54 -15 -54 -12 0 -15 13 -15 55 0 48 -2
55 -20 55 -11 0 -20 7 -20 15 0 12 13 15 61 15 44 0 60 -3 57 -12z m1453 -15
c11 -20 11 -27 2 -30 -15 -5 -17 -39 -3 -48 20 -12 11 -36 -12 -33 -19 2 -24
11 -30 52 -6 47 3 86 21 86 4 0 14 -12 22 -27z m-1365 -11 c6 -4 14 -17 18
-29 7 -21 4 -23 -28 -23 -20 0 -36 -5 -36 -11 0 -7 11 -9 30 -5 23 4 30 2 30
-10 0 -38 -76 -28 -86 12 -13 52 31 93 72 66z m182 -4 c7 -7 12 -20 12 -30 0
-14 -8 -18 -36 -18 -23 0 -33 -4 -28 -11 3 -6 17 -8 31 -4 20 5 24 2 21 -12
-4 -24 -51 -29 -72 -8 -34 34 -10 95 38 95 12 0 27 -5 34 -12z m106 6 c9 -3
16 -13 16 -21 0 -11 -7 -13 -30 -8 -27 6 -30 4 -30 -20 0 -24 3 -26 30 -20 23
5 30 3 30 -9 0 -19 -48 -32 -69 -19 -20 12 -27 69 -10 89 12 15 37 18 63 8z
m124 -6 c7 -7 12 -26 12 -43 0 -37 -15 -55 -46 -55 -29 0 -54 25 -54 55 0 45
58 73 88 43z m182 -9 c6 -10 10 -34 10 -53 0 -29 -4 -36 -20 -36 -17 0 -20 7
-20 40 0 22 -4 40 -10 40 -5 0 -10 -18 -10 -40 0 -33 -3 -40 -20 -40 -17 0
-20 7 -20 40 0 22 -4 40 -10 40 -5 0 -10 -18 -10 -40 0 -33 -3 -40 -20 -40
-18 0 -20 7 -20 55 l0 55 70 -2 c57 -1 73 -5 80 -19z m178 -36 c2 -43 0 -53
-12 -53 -12 0 -16 11 -16 40 0 40 -13 53 -24 24 -3 -9 -6 -27 -6 -40 0 -15 -6
-24 -15 -24 -11 0 -15 11 -15 41 0 52 -24 48 -28 -4 -2 -24 -8 -37 -18 -37
-10 0 -14 14 -14 54 l0 53 73 -1 72 -1 3 -52z m62 16 c0 -52 24 -48 28 4 2 24
8 37 18 37 10 0 14 -14 14 -55 l0 -55 -40 0 c-45 0 -60 18 -60 74 0 29 4 36
20 36 17 0 20 -7 20 -41z m174 25 c3 -9 6 -33 6 -55 0 -53 -27 -55 -32 -2 -4
53 -28 56 -28 3 0 -34 -3 -41 -17 -38 -14 3 -18 14 -19 48 0 25 1 48 3 53 8
15 81 8 87 -9z m66 -40 c0 -47 -3 -55 -17 -52 -14 2 -18 14 -19 48 0 53 2 60
22 60 10 0 14 -14 14 -56z m110 35 c11 -19 9 -20 -22 -17 -27 2 -33 0 -33 -17
0 -17 6 -19 33 -17 31 3 33 2 22 -17 -12 -24 -58 -29 -78 -9 -15 15 -16 53 -2
79 15 27 66 26 80 -2z m104 5 c3 -9 6 -33 6 -55 0 -39 0 -39 -39 -39 -31 0
-41 4 -46 20 -8 25 8 47 38 53 18 4 17 5 -5 6 -27 1 -35 11 -21 24 13 13 61 7
67 -9z m136 -39 c0 -42 -3 -55 -15 -55 -12 0 -15 13 -15 55 0 42 3 55 15 55
12 0 15 -13 15 -55z m110 40 c16 -20 16 -61 0 -80 -20 -25 -63 -15 -78 18 -12
24 -11 31 2 53 19 27 57 32 76 9z m123 7 c3 -4 7 -27 8 -52 3 -36 0 -45 -14
-48 -14 -3 -17 4 -17 38 0 53 -24 50 -28 -3 -2 -24 -8 -37 -18 -37 -10 0 -14
14 -14 55 l0 55 39 0 c22 0 42 -3 44 -8z m145 -9 c2 -8 -6 -13 -20 -14 -22 -1
-21 -1 5 -11 46 -17 26 -74 -24 -66 -34 5 -36 28 -2 29 l28 1 -27 10 c-27 9
-39 44 -21 62 11 11 56 2 61 -11z m110 5 c7 -7 12 -20 12 -30 0 -13 -8 -18
-30 -18 -16 0 -30 -5 -30 -11 0 -6 12 -9 27 -7 34 4 42 -19 9 -28 -35 -9 -54
1 -66 32 -20 51 41 99 78 62z m102 -2 c0 -8 -7 -16 -15 -20 -9 -3 -15 -19 -15
-41 0 -28 -4 -35 -20 -35 -18 0 -20 7 -20 55 l0 55 35 0 c23 0 35 -5 35 -14z
m50 -11 c0 -14 4 -25 9 -25 5 0 12 11 15 25 4 14 13 25 21 25 19 0 19 -14 -1
-67 -22 -57 -47 -57 -63 -2 -7 23 -15 47 -18 55 -3 9 2 14 16 14 16 0 21 -6
21 -25z m110 -30 c0 -42 -3 -55 -15 -55 -12 0 -15 13 -15 55 0 42 3 55 15 55
12 0 15 -13 15 -55z m108 43 c21 -21 13 -30 -18 -23 -27 6 -30 4 -30 -20 0
-24 3 -26 30 -20 31 7 39 -2 18 -23 -31 -31 -80 -4 -80 43 0 47 49 74 80 43z
m116 -4 c27 -27 20 -44 -19 -44 -19 0 -35 -5 -35 -11 0 -6 12 -9 28 -7 19 2
27 -2 27 -12 0 -23 -56 -26 -72 -4 -16 22 -17 66 -1 82 17 17 54 15 72 -4z
m104 -1 c2 -8 -6 -13 -20 -14 l-23 0 23 -9 c47 -19 34 -74 -17 -68 -35 4 -35
34 -1 31 24 -2 24 -2 -2 8 -27 10 -38 45 -21 63 11 11 56 2 61 -11z m-1273
-203 c3 -6 -1 -13 -10 -16 -19 -8 -30 0 -20 15 8 14 22 14 30 1z m125 -80 l0
-70 -38 0 c-46 0 -62 14 -62 55 0 37 16 55 47 55 16 0 21 4 17 15 -4 10 0 15
15 15 19 0 21 -5 21 -70z m-512 28 c7 -7 12 -26 12 -43 0 -35 -15 -55 -42 -55
-11 0 -18 -7 -18 -20 0 -13 -7 -20 -20 -20 -18 0 -20 7 -20 75 l0 75 38 0 c21
0 43 -5 50 -12z m102 -2 c0 -8 -7 -16 -15 -20 -9 -3 -15 -19 -15 -41 0 -27 -4
-35 -18 -35 -15 0 -17 8 -16 53 1 28 2 53 3 55 1 1 15 2 31 2 19 0 30 -5 30
-14z m106 -10 c13 -22 14 -29 2 -53 -10 -21 -20 -28 -43 -28 -23 0 -33 7 -43
28 -12 24 -11 31 2 53 10 14 26 24 41 24 15 0 31 -10 41 -24z m66 -8 l12 -33
8 33 c5 18 15 32 23 32 19 0 19 -14 -1 -67 -22 -57 -47 -57 -63 -2 -7 23 -15
47 -18 55 -3 9 1 14 11 14 10 0 22 -13 28 -32z m118 -23 c0 -48 -2 -55 -20
-55 -18 0 -20 7 -20 55 0 48 2 55 20 55 18 0 20 -7 20 -55z m226 38 c23 -30
16 -43 -21 -43 -19 0 -35 -5 -35 -11 0 -6 12 -9 28 -7 19 2 27 -2 27 -12 0
-10 -11 -15 -35 -15 -29 0 -36 5 -44 27 -14 40 6 78 40 78 15 0 33 -8 40 -17z
m104 3 c0 -7 -9 -16 -20 -19 -15 -4 -20 -14 -20 -41 0 -25 -4 -36 -15 -36 -12
0 -15 13 -15 55 l0 55 35 0 c23 0 35 -5 35 -14z"/>
<path d="M2325 4189 c-73 -10 -141 -29 -210 -60 l-40 -19 31 0 c18 0 66 -7
108 -16 l76 -15 47 39 c27 22 66 49 88 60 l40 21 -35 -1 c-19 0 -66 -5 -105
-9z"/>
<path d="M2483 4176 c-23 -8 -62 -25 -85 -39 -42 -24 -86 -64 -77 -70 17 -12
122 -47 156 -52 34 -5 55 -1 113 25 68 30 125 49 194 65 l29 6 -25 13 c-43 23
-160 56 -213 61 -29 3 -68 -1 -92 -9z"/>
<path d="M2784 4150 c39 -17 77 -29 85 -27 9 1 -10 13 -41 25 -91 34 -120 36
-44 2z"/>
<path d="M2039 4081 c-32 -7 -134 -69 -125 -78 1 -2 36 4 76 12 40 8 120 18
177 22 139 8 139 22 -2 43 -77 11 -75 11 -126 1z"/>
<path d="M2737 4071 c-72 -20 -177 -62 -177 -72 0 -3 22 -11 50 -17 27 -6 102
-32 166 -58 l116 -46 32 31 c17 16 49 37 70 46 l40 17 -25 19 c-53 43 -157 99
-180 98 -13 0 -54 -8 -92 -18z"/>
<path d="M2972 4045 c36 -24 69 -49 73 -55 3 -5 21 -10 38 -10 l32 0 -30 25
c-49 40 -131 85 -156 85 -19 0 -9 -10 43 -45z"/>
<path d="M3035 4071 c24 -23 151 -111 161 -111 7 0 21 -3 31 -7 32 -12 -6 27
-62 62 -51 32 -148 74 -130 56z"/>
<path d="M1879 4042 c-63 -30 -152 -102 -125 -102 19 0 88 39 132 75 57 47 55
56 -7 27z"/>
<path d="M2298 4043 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2099 4009 c-92 -9 -221 -41 -253 -62 -43 -29 -206 -241 -206 -269 0
-4 16 -8 35 -8 54 0 183 -29 248 -56 77 -31 107 -24 161 34 22 25 48 53 57 63
9 9 20 38 24 65 9 62 53 201 65 209 6 3 10 13 10 21 0 16 -19 16 -141 3z"/>
<path d="M2272 4010 c-25 -18 -72 -134 -88 -215 -8 -46 -11 -47 81 34 28 24
79 64 115 89 87 61 85 58 59 73 -26 13 -155 28 -167 19z"/>
<path d="M2534 3967 c56 -36 179 -131 233 -181 53 -49 64 -55 68 -40 4 11 14
40 24 66 l19 46 -62 28 c-82 37 -254 94 -280 93 -20 0 -20 -1 -2 -12z"/>
<path d="M2414 3917 c-74 -51 -101 -73 -181 -144 -59 -52 -63 -58 -63 -99 l0
-45 93 8 c115 10 446 10 508 1 46 -7 47 -7 53 25 7 30 2 36 -69 104 -75 71
-245 194 -268 192 -7 0 -39 -19 -73 -42z"/>
<path d="M3100 3950 c0 -3 14 -19 31 -36 17 -16 56 -60 87 -99 31 -38 59 -74
63 -78 3 -5 21 -2 39 5 l33 14 -95 96 c-71 71 -104 97 -127 100 -17 2 -31 2
-31 -2z"/>
<path d="M2964 3918 c-26 -18 -49 -38 -51 -44 -2 -5 18 -21 44 -34 27 -13 89
-52 138 -85 l90 -62 39 17 39 16 -48 59 c-26 33 -71 84 -98 113 -62 63 -85 66
-153 20z"/>
<path d="M1738 3905 c-52 -28 -95 -92 -122 -184 -22 -74 -20 -75 14 -12 32 58
97 149 138 194 31 33 30 33 -30 2z"/>
<path d="M3270 3873 c29 -32 60 -65 68 -73 8 -8 2 9 -12 38 -16 31 -40 61 -59
72 -53 33 -53 23 3 -37z"/>
<path d="M3350 3854 c0 -5 8 -28 18 -51 14 -36 22 -43 44 -43 44 0 41 16 -12
60 -27 23 -50 38 -50 34z"/>
<path d="M2875 3814 c-8 -20 -18 -53 -20 -73 -5 -32 -1 -41 39 -82 63 -65 92
-69 181 -23 39 19 73 39 77 43 8 7 -105 85 -204 141 l-57 31 -16 -37z"/>
<path d="M1551 3774 c-40 -37 -92 -95 -116 -129 -47 -65 -57 -89 -27 -63 24
21 79 49 126 63 37 11 41 16 66 86 15 41 30 82 35 92 14 32 -12 17 -84 -49z"/>
<path d="M3405 3740 c-3 -5 18 -44 46 -85 58 -82 64 -82 75 -3 5 37 2 46 -18
64 -26 24 -93 40 -103 24z"/>
<path d="M3311 3721 c-11 -7 -5 -23 27 -77 22 -38 49 -87 61 -109 11 -22 20
-33 21 -24 0 28 -31 182 -41 201 -10 19 -45 24 -68 9z"/>
<path d="M3232 3694 l-32 -14 57 -51 c32 -29 73 -68 92 -88 22 -24 31 -29 27
-16 -9 27 -100 185 -106 184 -3 0 -20 -7 -38 -15z"/>
<path d="M2109 3649 l-33 -41 35 7 c40 9 35 3 42 43 9 43 -5 40 -44 -9z"/>
<path d="M2840 3658 c0 -20 6 -27 26 -32 37 -10 38 -4 4 29 l-30 29 0 -26z"/>
<path d="M3102 3631 l-72 -36 83 -23 c46 -13 121 -39 167 -57 47 -19 86 -33
87 -31 4 4 -145 151 -172 170 -18 12 -28 10 -93 -23z"/>
<path d="M3535 3607 l-17 -54 31 -50 c28 -46 35 -51 78 -57 25 -3 48 -4 51 -2
5 5 -47 98 -94 169 l-32 48 -17 -54z"/>
<path d="M1602 3603 c-23 -54 -38 -163 -22 -163 6 0 41 15 78 34 69 34 240 96
267 96 28 0 15 18 -26 34 -65 26 -159 46 -220 46 l-56 0 -21 -47z"/>
<path d="M3421 3638 c0 -7 6 -49 13 -93 l12 -80 23 43 22 43 -31 50 c-33 51
-41 58 -39 37z"/>
<path d="M1512 3617 c-66 -28 -146 -96 -173 -148 -22 -44 -58 -195 -59 -246
l0 -32 55 58 c30 32 79 76 109 97 47 34 55 46 71 99 10 33 27 79 37 103 11 23
18 53 16 66 l-3 24 -53 -21z"/>
<path d="M2246 3618 l-79 -11 7 -74 c5 -68 8 -76 38 -100 18 -14 76 -70 128
-123 l95 -97 75 59 c41 32 130 96 197 141 l123 82 0 52 c0 44 -4 54 -22 65
-30 16 -452 20 -562 6z"/>
<path d="M2841 3583 c1 -15 4 -38 7 -50 l7 -21 43 25 c23 14 42 28 42 32 -1
18 -19 29 -57 36 -42 7 -43 6 -42 -22z"/>
<path d="M2078 3593 c-21 -3 -46 -30 -36 -38 2 -1 28 -19 58 -39 l55 -36 3 36
c7 79 -3 89 -80 77z"/>
<path d="M2986 3572 c-7 -12 122 -187 137 -187 25 0 267 50 267 55 -1 17 -334
140 -380 140 -10 0 -21 -4 -24 -8z"/>
<path d="M1854 3529 c-66 -22 -154 -57 -195 -77 l-74 -37 1 -95 c1 -88 36
-295 53 -311 3 -4 16 -10 27 -13 19 -6 25 1 48 61 41 103 143 285 222 400 55
77 68 103 57 107 -18 7 2 12 -139 -35z"/>
<path d="M2902 3522 c-40 -25 -42 -29 -38 -63 3 -20 13 -59 23 -88 14 -45 19
-52 37 -46 12 3 48 13 81 21 94 25 93 20 24 117 -34 48 -67 87 -73 86 -6 0
-30 -13 -54 -27z"/>
<path d="M2008 3523 c-73 -84 -274 -443 -293 -523 -5 -22 2 -31 58 -72 l64
-46 115 57 c63 31 159 77 214 100 54 24 100 44 101 45 1 1 -12 39 -28 86 -16
46 -40 129 -52 184 l-22 101 -64 42 c-35 24 -67 43 -71 43 -4 0 -14 -8 -22
-17z"/>
<path d="M3489 3508 c-7 -13 -18 -33 -26 -44 -13 -21 -13 -22 34 -16 26 2 49
6 51 7 3 3 -38 75 -44 75 -2 0 -9 -10 -15 -22z"/>
<path d="M2780 3443 c-83 -54 -316 -221 -329 -236 -9 -11 -8 -17 3 -27 12 -9
30 -6 93 18 43 16 137 49 208 72 72 24 131 43 132 44 0 0 -11 38 -25 82 l-27
82 -55 -35z"/>
<path d="M1537 3446 c-4 -15 -6 -31 -2 -34 10 -10 16 6 13 35 l-3 28 -8 -29z"/>
<path d="M2190 3414 c0 -49 81 -314 96 -314 2 0 34 23 69 51 l64 52 -44 51
c-40 47 -106 112 -163 160 -22 19 -22 19 -22 0z"/>
<path d="M3484 3417 c10 -7 45 -31 77 -54 l59 -43 -27 55 -27 55 -51 -1 c-43
0 -47 -2 -31 -12z"/>
<path d="M3627 3355 c21 -41 42 -74 48 -72 5 2 18 17 27 33 26 44 7 91 -42
104 -72 20 -73 17 -33 -65z"/>
<path d="M3247 3395 c-60 -13 -113 -25 -117 -27 -10 -4 85 -170 96 -168 11 3
113 120 155 178 l30 42 -28 -1 c-15 0 -77 -11 -136 -24z"/>
<path d="M1528 3378 c-19 -15 -44 -132 -56 -254 l-8 -82 51 -6 c27 -3 61 -9
74 -13 22 -6 23 -4 17 18 -17 62 -37 182 -43 262 -3 48 -9 87 -13 87 -3 0 -13
-6 -22 -12z"/>
<path d="M3364 3328 c-27 -35 -68 -84 -91 -109 l-43 -46 41 -87 41 -87 31 24
c28 22 34 35 49 113 18 94 34 254 26 254 -3 0 -27 -28 -54 -62z"/>
<path d="M3496 3368 c4 -7 17 -26 29 -43 12 -16 34 -52 50 -78 l28 -49 25 26
26 25 -24 26 c-29 31 -124 105 -134 105 -4 0 -4 -6 0 -12z"/>
<path d="M3441 3340 c-1 -38 -26 -212 -37 -256 l-7 -27 29 17 c16 10 41 28 57
40 l27 22 -24 95 c-14 51 -29 105 -35 119 -9 24 -10 24 -10 -10z"/>
<path d="M3011 3335 c-46 -14 -87 -26 -92 -27 -11 -4 62 -147 113 -219 l42
-60 68 68 c37 37 68 63 68 57 0 -5 -27 -36 -60 -69 -33 -33 -60 -64 -60 -70 0
-6 18 -29 40 -50 l41 -40 55 30 c31 17 58 33 60 37 10 15 -170 369 -186 367
-3 0 -43 -11 -89 -24z"/>
<path d="M3475 3345 c4 -11 18 -58 30 -105 24 -86 33 -96 64 -65 12 12 8 24
-27 82 -22 37 -48 77 -57 88 l-17 20 7 -20z"/>
<path d="M1375 3255 l-95 -94 0 -63 c0 -35 3 -79 6 -99 l6 -36 50 33 c28 19
58 34 69 34 26 0 35 24 43 115 7 73 18 142 32 188 11 35 -19 14 -111 -78z"/>
<path d="M3704 3287 l-21 -32 40 -50 40 -50 -8 45 c-4 24 -13 61 -19 82 l-11
38 -21 -33z"/>
<path d="M2825 3278 c-81 -28 -339 -120 -343 -123 -1 -1 27 -47 63 -101 37
-54 92 -151 122 -214 31 -63 58 -117 59 -119 3 -4 142 116 268 232 l69 63 -41
54 c-22 30 -60 93 -84 141 -24 49 -45 88 -48 88 -3 0 -32 -10 -65 -21z"/>
<path d="M1245 3205 c-4 -36 -5 -68 -2 -71 2 -3 8 25 12 61 4 37 5 69 2 72 -3
2 -8 -25 -12 -62z"/>
<path d="M3633 3208 l-23 -24 39 -89 c21 -50 46 -117 56 -150 l18 -60 10 33
c8 26 5 53 -16 132 -15 55 -34 118 -44 141 l-18 41 -22 -24z"/>
<path d="M3720 3112 c15 -48 30 -96 33 -107 2 -11 5 6 6 38 1 46 -4 68 -25
103 -41 69 -44 61 -14 -34z"/>
<path d="M2385 3155 c-47 -40 -44 -42 19 -14 39 17 45 23 35 35 -6 8 -12 14
-13 14 0 0 -19 -16 -41 -35z"/>
<path d="M3559 3147 l-25 -23 12 -94 c7 -52 13 -134 13 -183 l1 -87 37 15 c59
25 103 63 103 90 0 44 -97 307 -113 305 -1 0 -14 -11 -28 -23z"/>
<path d="M2363 3108 c-40 -17 -73 -34 -73 -38 0 -29 168 -324 241 -424 8 -10
179 52 179 65 0 5 -22 55 -49 111 -66 137 -185 318 -208 318 -10 -1 -51 -15
-90 -32z"/>
<path d="M3449 3072 c-55 -36 -57 -39 -77 -111 -19 -70 -19 -77 -3 -125 14
-44 21 -53 51 -63 19 -7 54 -12 78 -12 l42 -1 0 75 c0 86 -20 275 -29 274 -3
0 -31 -17 -62 -37z"/>
<path d="M1247 3087 c-9 -23 -7 -134 3 -167 14 -49 22 5 13 97 -5 55 -11 81
-16 70z"/>
<path d="M2235 3033 c-16 -14 -48 -42 -70 -62 -22 -20 -81 -74 -132 -120 l-92
-83 43 -62 c24 -33 60 -95 81 -136 21 -41 39 -77 41 -79 8 -9 388 120 407 138
4 4 -23 52 -59 107 -37 54 -91 149 -121 209 -30 61 -57 111 -61 112 -4 1 -20
-9 -37 -24z"/>
<path d="M2031 2959 l-185 -89 40 -43 c21 -23 40 -43 41 -46 1 -2 50 41 109
95 59 55 126 116 148 136 23 21 39 37 36 37 -3 0 -88 -40 -189 -90z"/>
<path d="M1463 3014 c-7 -18 12 -282 23 -321 6 -21 7 -21 63 15 31 20 57 37
59 38 1 1 11 34 23 73 19 70 19 75 2 127 -18 58 -30 66 -120 80 -36 5 -44 3
-50 -12z"/>
<path d="M3777 2985 c-8 -55 2 -185 14 -185 13 0 22 88 15 147 -9 80 -22 96
-29 38z"/>
<path d="M1384 3000 c-23 -12 -52 -32 -64 -45 l-24 -25 24 -83 c25 -90 84
-227 97 -227 4 1 18 9 31 20 21 18 22 22 12 82 -5 35 -13 116 -16 181 -4 64
-10 117 -13 117 -3 0 -24 -9 -47 -20z"/>
<path d="M3329 2997 c-13 -10 -14 -17 -4 -41 13 -32 19 -27 30 22 7 34 0 39
-26 19z"/>
<path d="M2924 2872 c-78 -70 -140 -128 -139 -130 4 -5 348 159 364 173 4 5
-70 85 -78 84 -3 0 -69 -57 -147 -127z"/>
<path d="M1205 2980 c-12 -20 -11 -178 2 -210 9 -22 11 -17 17 40 8 79 -5 193
-19 170z"/>
<path d="M1647 2963 c11 -33 16 -37 23 -17 9 23 4 34 -15 34 -9 0 -12 -6 -8
-17z"/>
<path d="M3238 2943 c-27 -14 -48 -28 -48 -32 1 -8 80 -68 102 -76 27 -11 42
53 23 98 -8 20 -19 37 -23 36 -4 0 -28 -12 -54 -26z"/>
<path d="M1683 2927 c-7 -24 -6 -43 4 -72 7 -21 15 -40 17 -42 5 -6 106 50
106 59 0 7 -45 42 -91 72 -25 16 -26 16 -36 -17z"/>
<path d="M3030 2837 c-151 -75 -265 -127 -279 -127 -16 0 -14 -14 24 -131 19
-59 40 -138 46 -177 l12 -70 70 -46 c57 -38 72 -44 84 -34 49 40 237 366 291
504 l20 50 -64 47 c-36 26 -68 47 -72 46 -4 0 -63 -28 -132 -62z"/>
<path d="M1271 2871 c-15 -28 -10 -62 30 -191 38 -124 42 -131 69 -105 l20 21
-40 98 c-22 54 -46 121 -53 150 -13 49 -14 50 -26 27z"/>
<path d="M3736 2862 c-4 -11 -2 -50 4 -88 5 -38 10 -73 10 -79 0 -5 5 -2 11 7
6 11 7 47 3 93 -7 76 -18 101 -28 67z"/>
<path d="M1651 2821 c-14 -50 -13 -54 13 -35 16 11 18 19 9 46 l-10 33 -12
-44z"/>
<path d="M1778 2829 c-31 -16 -59 -32 -61 -37 -3 -4 11 -43 31 -85 l37 -78 67
68 67 68 -43 46 -42 46 -56 -28z"/>
<path d="M3330 2831 c0 -19 17 -38 25 -29 7 7 -7 48 -16 48 -5 0 -9 -9 -9 -19z"/>
<path d="M3680 2805 c-13 -14 -45 -33 -71 -42 -51 -18 -59 -33 -59 -112 0 -20
-9 -77 -19 -128 l-18 -92 31 21 c47 33 166 155 175 181 8 20 -2 183 -12 193
-2 2 -14 -7 -27 -21z"/>
<path d="M3310 2788 c0 -30 -154 -322 -225 -428 -93 -139 -94 -140 -63 -140
36 0 220 64 314 110 l84 41 0 62 c0 72 -13 169 -36 268 -13 59 -20 72 -45 84
-22 12 -29 13 -29 3z"/>
<path d="M1241 2742 c-1 -54 14 -95 52 -142 15 -19 18 -20 12 -5 -4 11 -19 56
-32 100 -32 108 -31 107 -32 47z"/>
<path d="M1658 2763 c-17 -11 -30 -31 -37 -57 -16 -65 -43 -263 -39 -291 3
-24 6 -22 48 32 25 32 66 81 92 110 l47 52 -37 85 c-20 47 -39 86 -42 86 -3 0
-17 -8 -32 -17z"/>
<path d="M3394 2744 c13 -43 35 -163 41 -224 12 -125 14 -131 35 -118 20 13
46 126 59 259 l7 77 -41 6 c-22 3 -55 8 -74 12 -30 6 -33 5 -27 -12z"/>
<path d="M1861 2683 c-34 -37 -61 -73 -61 -79 0 -10 54 -101 91 -155 l16 -23
84 22 c46 13 87 26 91 30 4 4 -4 32 -18 61 -28 61 -124 211 -135 211 -4 0 -35
-30 -68 -67z"/>
<path d="M1538 2687 c-53 -36 -53 -23 -10 -177 l27 -95 7 60 c9 71 26 179 34
218 8 34 0 33 -58 -6z"/>
<path d="M2653 2638 c-35 -28 -63 -54 -63 -59 0 -12 123 -141 181 -190 l47
-39 -18 82 c-21 97 -67 245 -78 251 -4 3 -35 -18 -69 -45z"/>
<path d="M2603 2646 c-29 -11 -51 -25 -50 -31 7 -21 33 -13 72 20 22 19 38 34
35 34 -3 -1 -29 -11 -57 -23z"/>
<path d="M3756 2649 c-7 -21 -18 -154 -12 -149 6 7 26 154 21 159 -3 2 -7 -2
-9 -10z"/>
<path d="M1246 2585 c4 -27 12 -65 18 -83 l12 -33 23 31 23 32 -24 26 c-14 15
-33 38 -42 52 -17 25 -17 25 -10 -25z"/>
<path d="M1441 2613 l-22 -18 51 -82 c28 -46 53 -83 54 -83 2 0 -7 37 -20 83
-13 45 -24 90 -24 100 0 9 -4 17 -9 17 -5 0 -18 -8 -30 -17z"/>
<path d="M2485 2598 c-27 -11 -119 -44 -203 -73 -85 -30 -156 -55 -158 -58 -3
-2 6 -41 19 -85 l23 -81 104 71 c58 39 147 102 198 140 82 61 91 71 82 88 -6
11 -12 20 -13 19 -1 0 -24 -9 -52 -21z"/>
<path d="M3666 2533 c-32 -34 -80 -77 -107 -95 -45 -31 -50 -39 -83 -133 -47
-134 -52 -155 -35 -155 31 0 122 52 169 96 34 32 56 63 70 101 20 53 55 227
48 241 -2 4 -29 -21 -62 -55z"/>
<path d="M1690 2493 c-45 -54 -86 -106 -91 -116 -9 -15 -7 -17 13 -13 13 3 73
15 133 27 61 11 115 24 121 28 8 4 -4 34 -35 88 -25 45 -49 83 -53 83 -4 0
-43 -44 -88 -97z"/>
<path d="M1382 2562 c-33 -21 -27 -44 21 -83 82 -68 141 -103 104 -61 -8 9
-35 49 -59 88 -42 69 -44 71 -66 56z"/>
<path d="M2495 2514 c-38 -30 -128 -93 -198 -142 l-129 -87 4 -52 c3 -29 7
-55 10 -59 24 -24 431 -28 593 -6 l50 7 -1 70 -1 70 -129 128 c-70 70 -128
127 -128 126 -1 0 -32 -25 -71 -55z"/>
<path d="M1312 2485 c-24 -31 -28 -73 -9 -97 11 -15 94 -44 102 -36 2 2 -13
39 -34 82 l-37 79 -22 -28z"/>
<path d="M1390 2455 c0 -3 11 -28 25 -55 25 -49 26 -50 70 -50 25 0 45 5 45
10 0 6 -4 10 -9 10 -5 0 -36 20 -70 45 -34 25 -61 43 -61 40z"/>
<path d="M1998 2437 c-43 -12 -78 -27 -78 -33 0 -6 27 -48 60 -93 l61 -82 47
27 c26 14 51 30 55 34 11 10 -42 170 -56 169 -7 -1 -47 -11 -89 -22z"/>
<path d="M1795 2384 c-27 -7 -83 -19 -124 -25 l-73 -12 68 -32 c57 -27 229
-88 309 -109 11 -3 26 -2 34 3 10 7 -1 29 -50 100 -71 100 -72 101 -164 75z"/>
<path d="M3451 2338 c0 -28 2 -30 9 -13 12 28 12 45 0 45 -5 0 -10 -15 -9 -32z"/>
<path d="M1326 2342 c-7 -6 57 -123 94 -175 30 -42 30 -42 50 20 12 39 11 44
-15 90 -15 26 -29 49 -30 50 -4 6 -95 20 -99 15z"/>
<path d="M3340 2309 c-41 -21 -121 -53 -177 -71 -57 -18 -103 -35 -103 -38 0
-11 135 -51 206 -61 89 -13 110 -7 133 39 20 39 39 173 25 171 -5 -1 -43 -19
-84 -40z"/>
<path d="M1473 2332 l-23 -7 20 -32 c11 -18 23 -33 27 -33 5 0 18 18 31 40
l23 40 -28 -1 c-15 -1 -38 -4 -50 -7z"/>
<path d="M1531 2272 l-22 -43 36 -55 c20 -30 37 -54 39 -54 2 0 -1 21 -5 48
-5 26 -12 70 -17 97 l-8 50 -23 -43z"/>
<path d="M1630 2301 c0 -15 181 -181 197 -181 13 0 90 37 122 58 9 7 -16 19
-87 41 -55 17 -130 44 -166 61 -36 16 -66 25 -66 21z"/>
<path d="M2843 2249 c4 -34 9 -64 11 -66 7 -7 89 16 102 29 11 11 1 21 -53 57
l-66 43 6 -63z"/>
<path d="M1596 2193 c18 -114 32 -146 58 -139 11 3 27 6 34 6 19 0 15 14 -32
92 -24 41 -47 84 -50 96 -16 50 -22 17 -10 -55z"/>
<path d="M2098 2244 c-21 -13 -38 -28 -38 -33 0 -15 24 -28 63 -34 35 -5 37
-4 37 21 0 34 -10 72 -19 71 -3 0 -23 -11 -43 -25z"/>
<path d="M1630 2252 c0 -6 15 -36 33 -69 66 -115 61 -111 109 -90 l28 12 -85
78 c-47 43 -85 74 -85 69z"/>
<path d="M3595 2200 c-8 -13 -112 -65 -146 -73 -20 -5 -28 -15 -33 -39 -4 -18
-17 -59 -30 -90 -13 -32 -20 -58 -16 -58 5 0 44 33 87 74 77 72 174 196 154
196 -5 0 -13 -5 -16 -10z"/>
<path d="M1482 2161 c-17 -55 -15 -64 16 -94 22 -21 38 -27 71 -27 41 0 43 1
30 19 -8 11 -32 47 -54 80 -22 33 -43 60 -46 61 -3 0 -11 -17 -17 -39z"/>
<path d="M2989 2186 c-24 -7 -56 -32 -90 -70 -48 -53 -55 -66 -65 -125 -9 -55
-29 -112 -71 -210 -9 -21 166 -9 287 19 92 22 97 24 148 79 30 31 81 97 114
146 l61 90 -84 6 c-83 6 -162 26 -229 59 -29 14 -41 15 -71 6z"/>
<path d="M1912 2141 l-73 -38 33 -24 c18 -14 75 -52 127 -83 104 -64 116 -65
137 -5 22 59 17 81 -28 132 -60 66 -97 69 -196 18z"/>
<path d="M2868 2163 c-12 -3 -18 -14 -18 -36 l0 -31 35 34 c19 19 35 35 35 37
0 4 -31 2 -52 -4z"/>
<path d="M2110 2152 c0 -5 11 -19 25 -32 l25 -23 0 25 c0 14 -7 29 -16 32 -22
8 -34 8 -34 -2z"/>
<path d="M2180 2131 c0 -11 -3 -26 -6 -34 -10 -28 297 -277 341 -277 17 0 160
106 248 183 64 56 67 61 67 104 l0 45 -92 -9 c-115 -10 -411 -10 -495 -1 -58
7 -63 6 -63 -11z"/>
<path d="M3391 2114 c-1 -16 -97 -161 -137 -208 -19 -22 -32 -41 -30 -43 13
-13 88 42 111 80 29 51 67 157 61 172 -3 6 -5 6 -5 -1z"/>
<path d="M1778 2078 c-16 -5 -28 -16 -28 -24 0 -15 93 -125 158 -188 38 -37
42 -38 74 -27 40 14 108 58 108 70 0 5 -26 24 -57 41 -32 18 -93 57 -135 86
-84 58 -80 57 -120 42z"/>
<path d="M1678 2043 c-10 -2 -18 -9 -18 -15 0 -6 42 -53 94 -104 81 -81 98
-94 127 -94 l33 0 -60 63 c-34 34 -78 83 -99 110 -37 46 -43 49 -77 40z"/>
<path d="M2166 2038 c-2 -7 -12 -35 -21 -62 -15 -40 -15 -50 -4 -57 52 -33
319 -124 342 -116 5 1 -29 28 -74 59 -46 30 -117 85 -157 122 -40 36 -75 66
-78 66 -2 0 -6 -6 -8 -12z"/>
<path d="M2780 1995 c-34 -36 -156 -133 -214 -169 l-29 -19 27 -14 c15 -8 59
-15 99 -16 l72 -2 27 51 c29 53 62 164 56 190 -2 11 -13 5 -38 -21z"/>
<path d="M1551 2013 c0 -10 90 -85 95 -80 2 2 -4 22 -14 45 -15 37 -21 42 -49
42 -18 0 -33 -3 -32 -7z"/>
<path d="M1660 1975 c1 -26 53 -91 86 -107 21 -10 39 -17 40 -15 2 2 -26 32
-61 67 -36 36 -65 60 -65 55z"/>
<path d="M2084 1879 c-15 -15 -47 -38 -71 -49 l-45 -21 24 -18 c13 -10 52 -37
88 -60 57 -37 69 -41 105 -36 63 10 145 34 208 62 l58 26 -79 23 c-44 13 -121
41 -170 62 l-91 39 -27 -28z"/>
<path d="M3212 1829 c-45 -19 -155 -100 -147 -108 8 -8 130 59 163 90 19 17
32 32 30 33 -1 2 -22 -5 -46 -15z"/>
<path d="M1760 1832 c0 -23 165 -122 201 -122 6 0 -20 23 -58 51 -63 48 -143
87 -143 71z"/>
<path d="M1880 1806 c0 -13 173 -117 190 -114 16 2 2 16 -61 61 -44 31 -92 57
-105 57 -13 0 -24 -2 -24 -4z"/>
<path d="M3030 1773 c-30 -7 -109 -17 -174 -21 -75 -4 -121 -11 -123 -18 -5
-15 101 -34 186 -34 56 0 68 4 122 40 80 55 80 57 -11 33z"/>
<path d="M2437 1753 c-59 -29 -192 -73 -226 -73 -25 -1 -24 -2 10 -20 54 -29
177 -60 234 -60 56 0 148 38 203 85 l34 28 -57 23 c-99 40 -145 44 -198 17z"/>
<path d="M2683 1743 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M2672 1669 c-23 -20 -61 -47 -85 -58 l-42 -22 90 5 c91 4 233 42 272
72 16 12 12 13 -32 14 -27 0 -75 6 -106 13 l-55 14 -42 -38z"/>
<path d="M2110 1660 c18 -12 166 -60 183 -60 7 0 -26 16 -73 35 -82 33 -144
48 -110 25z"/>
<path d="M1180 1260 c0 -113 1 -120 20 -120 19 0 20 7 20 120 0 113 -1 120
-20 120 -19 0 -20 -7 -20 -120z"/>
<path d="M2700 1260 l0 -120 25 0 25 0 0 120 0 120 -25 0 -25 0 0 -120z"/>
<path d="M3404 1367 c-2 -7 -3 -60 -2 -118 3 -99 4 -104 26 -107 22 -3 22 -2
22 117 0 115 -1 121 -20 121 -11 0 -23 -6 -26 -13z"/>
<path d="M4040 1325 c0 -38 3 -45 20 -45 17 0 20 7 20 45 0 38 -3 45 -20 45
-17 0 -20 -7 -20 -45z"/>
<path d="M1305 950 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10 -2
0 -7 -4 -10 -10z"/>
<path d="M1488 943 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1706 941 c-9 -14 4 -41 20 -41 8 0 14 10 14 25 0 25 -23 35 -34 16z"/>
<path d="M2590 910 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2830 925 c0 -16 6 -25 15 -25 9 0 15 9 15 25 0 16 -6 25 -15 25 -9
0 -15 -9 -15 -25z"/>
<path d="M3218 943 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3698 943 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2633 674 c-8 -21 13 -42 28 -27 13 13 5 43 -11 43 -6 0 -13 -7 -17
-16z"/>
<path d="M2140 665 c0 -16 6 -25 15 -25 9 0 15 9 15 25 0 16 -6 25 -15 25 -9
0 -15 -9 -15 -25z"/>
<path d="M2340 665 c0 -16 6 -25 15 -25 9 0 15 9 15 25 0 16 -6 25 -15 25 -9
0 -15 -9 -15 -25z"/>
<path d="M2758 683 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
